import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import PodcastIcon from "../../Podcasticon"
import Finectlogo from "../../Finectlogo"

export default function Header(props) {
  const { openMenu, toggle } = props
  const logoPath = process.env.GATSBY_LOGO_PATH || "/imgs/logo.gif"

  return (
    <div className="Header">
      <div className="Header-container">
        <div className="burguer floatl">
          <button
            className={
              openMenu
                ? "hamburger hamburger-collapse-r is-active"
                : "hamburger hamburger-collapse-r"
            }
            type="button"
            onClick={toggle}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className="clearfix">
          <Link className="Header-floatl" to="/">
            <img className="Header-logo" src={logoPath} alt="Logo" />
          </Link>
          <div className="Header-floatr">
            <ul className="Header-social">
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://whatsapp.com/channel/0029VadiIhA4CrfkiMEHPi0b"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-whatsapp"
                    className="fa-icon"
                  />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://open.spotify.com/show/09UislSj5XnNvHsWvonRlT"
                >
                  <PodcastIcon color="#112149" className="fa-icon" />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/efpa_es"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-x-twitter"
                    className="fa-icon"
                  />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/efpa.espana/"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-facebook-f"
                    className="fa-icon"
                  />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/efpa_es/"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-instagram"
                    className="fa-icon"
                  />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.finect.com/grupos/efpa_espana/conoce_a_efpa"
                >
                  <Finectlogo color="#112149" className={"fa-icon"} />
                </a>
              </li>
            </ul>
            <ul className="Header-social">
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://vimeo.com/efpaespana"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-vimeo"
                    className="fa-icon"
                  />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/user/efpaes"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-youtube"
                    className="fa-icon"
                  />
                </a>
              </li>
              <li className="Header-list">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/efpa-spain/"
                >
                  <FontAwesomeIcon
                    icon="fa-brands fa-linkedin-in"
                    className="fa-icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="Header-acceso">
          <Link
            className="Header-acceso-link"
            to={`${process.env.GATSBY_INTRANET_URL}/login`}
          >
            Intranet asociados
          </Link>
          <Link
            className="Header-acceso-link test"
            to={`${process.env.GATSBY_INTRANET_ENTIDADES_URL}/login`}
          >
            Intranet entidades
          </Link>
          <Link
            className="Header-acceso-link test"
            to={`${process.env.GATSBY_INTRANET_INSC_EXAMENES_URL}/login`}
          >
            Inscripción exámenes
          </Link>
        </div>
      </div>
    </div>
  )
}
