import React, { useEffect, useState } from "react"
import Header from "./Header"
import Footer from "./Footer"
import Navbar from "../Navbar"
import { Helmet } from "react-helmet"
import { footerItemsSecondary } from "../../data/footer"
import moment from "moment"
import "moment/locale/es"
import useDataCollect from "../../service/useDataCollect"
import initFontAwesome from "../../service/initFontAwesome"

initFontAwesome()

export default function Layout(props) {
  const [toggle, setToggle] = useState(false)
  const GTM_KEY = "G-9BQFJ9BRWS"
  useDataCollect()

  useEffect(() => {
    moment.locale("es")
  }, [])

  const toggleMenu = () => {
    setToggle(prev => !prev)
  }

  return (
    <div className="Layout">
      <Header openMenu={toggle} toggle={toggleMenu} />
      <Navbar useOptions openMenu={toggle} className="NavbarSecondary" />
      {props.children}
      <Helmet>
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.7.2/css/all.min.css"
          integrity="sha512-Evv84Mr4kqVGRNSgIGL/F/aIDqQb7xQ2vcrdIwxfjThSH8CSR7PBEakCr51Ck+w+/U6swU2Im1vVX0SVk9ABhg=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-9BQFJ9BRWS"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTM_KEY}');`}
        </script>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TNRHZBX');`}</script>
        <script
          src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="15fdeb90-5a6a-4a39-a65d-24d91c913114"
        ></script>
        <script type="text/javascript">function OptanonWrapper() {}</script>
      </Helmet>
      <Footer footerItems={footerItemsSecondary} />
    </div>
  )
}
